import { useEffect, useRef, useMemo, useState } from 'react';
import {
  DefaultGenerics,
  ExtendableGenerics,
  OwnUserResponse,
  StreamChat,
  TokenOrProvider,
  UserResponse,
} from 'stream-chat';
import logger from '../utils/logger';

export const useConnectUser = <
  SCG extends ExtendableGenerics = DefaultGenerics,
>(
  apiKey: string,
  userToConnect: OwnUserResponse<SCG> | UserResponse<SCG>,
  userTokenOrProvider: TokenOrProvider,
) => {
  const clientRef = useRef<StreamChat<SCG> | null>(null);
  const [clientState, setClientState] = useState<StreamChat<SCG> | null>(null);
  const connectingRef = useRef(false);
  const previousUserIdRef = useRef<string | null>(null);

  const memoizedUserToConnect = useMemo(
    () => ({
      id: userToConnect.id,
      name: userToConnect.name,
      image: userToConnect.image,
    }),
    [userToConnect.id, userToConnect.name, userToConnect.image],
  );

  useEffect(() => {
    logger.info('useConnectUser effect triggered', {
      userId: userToConnect.id,
    });

    if (
      connectingRef.current ||
      (clientState && clientState.user?.id === userToConnect.id)
    ) {
      logger.info('Connection already in progress or user already connected', {
        userId: userToConnect.id,
      });
      return;
    }

    const connectUser = async () => {
      connectingRef.current = true;
      logger.info('Connecting user', { userId: userToConnect.id });

      try {
        if (!clientRef.current) {
          clientRef.current = new StreamChat<SCG>(apiKey, {
            enableInsights: true,
            enableWSFallback: true,
          });
        }

        await clientRef.current?.connectUser(
          {
            ...memoizedUserToConnect,
            image:
              memoizedUserToConnect.image ||
              'https://actlorwoophuyzxnpwny.supabase.co/storage/v1/object/public/avatars/outlook-default-person.png',
            name: memoizedUserToConnect.name || 'HashiDefault',
          },
          userTokenOrProvider,
        );

        setClientState(clientRef.current);
        previousUserIdRef.current = userToConnect.id;
        logger.info('User connected successfully', {
          userId: userToConnect.id,
        });
      } catch (error) {
        logger.error('Failed to connect user', error);
        logger.error('User object:', userToConnect);
        logger.error('Token:', userTokenOrProvider);
      } finally {
        connectingRef.current = false;
      }
    };

    connectUser();

    return () => {
      if (clientState && clientState.user?.id !== userToConnect.id) {
        logger.info('Disconnecting user', {
          userId: clientState.user?.id,
        });
        clientState.disconnectUser().catch((e) => {
          logger.error('Failed to disconnect user', e);
        });
      }
    };
  }, [apiKey, memoizedUserToConnect, userToConnect, userTokenOrProvider]);

  return clientState;
};
