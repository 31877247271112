import React from 'react';
import InviteFriends from './InviteFriends/InviteFriends';

interface EmailInputProps {
  onClose: () => void;
  onError: (errorMessage: string) => void;
  onInviteSent: () => void;
  refreshInvitations: () => void;
}

const EmailInput: React.FC<EmailInputProps> = ({ onClose, onError, onInviteSent, refreshInvitations }) => {
  return (
    <InviteFriends
      onError={onError}
      onInviteSent={onInviteSent}
      onClose={onClose}
      refreshInvitations={refreshInvitations}
      isOverlay={true}
    />
  );
};

export default EmailInput;