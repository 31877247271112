import React from 'react';
import InputSkeleton from './InputSkeleton';
import ButtonSkeleton from './ButtonSkeleton';

interface Props {
  className?: string;
}

const SkeletonLoader = ({ className = '' }: Props) => {
  return (
    <div className={`space-y-4 w-full max-w-md ${className}`}>
      <InputSkeleton />
      <InputSkeleton />
      <InputSkeleton />
      <ButtonSkeleton />
      <InputSkeleton />
      <ButtonSkeleton />
    </div>
  );
};

export default SkeletonLoader;
