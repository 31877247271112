import React, { useEffect, useState } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';
import { supabase } from '../../supabaseClient';
import streamLogo from '../../assets/ProfilePic_LogoMark_GrdntOnWt.png';
import CreateChannel from '../CreateChannel/CreateChannel';

import type { StreamChatGenerics } from '../../types';
import { PlusIcon } from 'lucide-react';

type Props = {
  onCreateChannel: () => void;
};

const MessagingChannelListHeader: React.FC<Props> = React.memo(() => {
  const { client } = useChatContext<StreamChatGenerics>();
  const [userName, setUserName] = useState<string>('');
  const [showCreateChannel, setShowCreateChannel] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (client.user?.id) {
        try {
          const { data, error } = await supabase
            .from('profiles')
            .select('name')
            .eq('id', client.user.id)
            .single();

          if (error) throw error;
          if (data) setUserName(data.name);
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [client.user?.id]);

  return (
    <>
      <div className="messaging__channel-list__header  w-full !px-0 !pt-2">
        <Avatar image={client.user?.image || streamLogo} name={userName} />
        <div className="messaging__channel-list__header__name">{userName}</div>
        <button
          className="messaging__channel-list__header__button"
          onClick={() => setShowCreateChannel(true)}
        >
          <PlusIcon className="w-4 h-4 text-white" />
        </button>
      </div>
      {showCreateChannel && (
        <CreateChannel onClose={() => setShowCreateChannel(false)} />
      )}
    </>
  );
});

export default MessagingChannelListHeader;
