import { ReactNode } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  showCloseButton?: boolean;
  className?: string;
}

const Modal = ({
  isOpen,
  onClose,
  children,
  showCloseButton = false,
  className = '',
}: Props) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={`fixed inset-0 mx-auto min-w-full z-10 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-opacity-50 bg-neutral-600 outline-none focus:outline-none ${className}`}
    >
      <div className="relative max-w-[90%] mx-auto my-6">
        <div className="relative bg-neutral-800 text-white py-4 px-6 rounded-lg shadow-lg border border-gray-400 flex flex-col w-6/7">
          <button
            className="py-1 pr-2 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={onClose}
          >
            <span className="text-white h-6 w-6 text-2xl block">×</span>
          </button>
          <div className="relative p-3 lg:p-6 flex-auto">{children}</div>
          {showCloseButton && (
            <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                type="button"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
