import ReactDOM from 'react-dom/client';
import './index.css';
import React from 'react';
import Router from './Router';
import { ThemeContextProvider } from './context';
import './i18n';

const targetOrigin =
  process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);
root.render(
  <React.StrictMode>
    <ThemeContextProvider targetOrigin={targetOrigin}>
      <Router />
    </ThemeContextProvider>
  </React.StrictMode>,
);
