import { useEffect, useRef, useState } from 'react';
import { usePreviousDistinct, useUpdateEffect } from 'react-use';
import { useMessageContext, useUserRole } from 'stream-chat-react';

interface TextReplaceEffectProps {
  text: string;
  typingSpeed?: number;
  isLastMessage?: boolean;
}

const TypewriterEffect = ({
  text,
  typingSpeed = 100,
  isLastMessage,
}: TextReplaceEffectProps) => {
  const prevText = usePreviousDistinct(text);
  const [displayText, setDisplayText] = useState(text);
  const [isTranslating, setIsTranslating] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const textRef = useRef<HTMLSpanElement>(null);

  const { message } = useMessageContext();
  const { isMyMessage } = useUserRole(message);

  useUpdateEffect(() => {
    isLastMessage && setIsTranslating(true);
  }, [isLastMessage, text]);

  useEffect(() => {
    if (!isLastMessage && text) setDisplayText(text);
  }, [isLastMessage, text]);

  useEffect(() => {
    if (!text) return;
    if (isLastMessage && isTranslating) {
      const maxLength = Math.max(prevText?.length ?? 0, text.length);
      let currentIndex = 0;
      let currentSpeed = typingSpeed;
      let intervalId: NodeJS.Timeout;

      const animateText = () => {
        setDisplayText(() => {
          const newText =
            text.slice(0, currentIndex) + displayText?.slice(currentIndex);
          return newText.padEnd(maxLength, ' ');
        });
        setCursorPosition(currentIndex);

        currentIndex++;
        clearInterval(intervalId);
        currentSpeed = Math.max(currentSpeed - 2, 10);
        intervalId = setInterval(animateText, currentSpeed);

        if (currentIndex > maxLength /* text.length */) {
          clearInterval(intervalId);
          setIsTranslating(false);
          setCursorPosition(-1);
          // setDisplayText(text); // Immediately set the final text
        }
      };

      intervalId = setInterval(() => {
        animateText();
      }, currentSpeed);

      return () => clearInterval(intervalId);
    }
  }, [isLastMessage, isTranslating, text, prevText]); // Add prevText to dependencies

  return (
    <span
      ref={textRef}
      className="inline-block"

      /* className={classNames(
      'inline-block transition-opacity transition-colors duration-300 ',
      isTranslated ? 'text-white' : 'text-[#3497da]',
    )} */
    >
      {displayText &&
        displayText.split('').map((char, index) => {
          if (char === 'undefined' || char === undefined) return null;
          return (
            <span
              key={index}
              className="inline transition-all duration-300"
              style={{
                opacity: isTranslating ? (index < cursorPosition ? 0.5 : 1) : 1,
                color:
                  isTranslating && index <= cursorPosition + 1
                    ? isMyMessage
                      ? '#fde68a'
                      : '#3497da'
                    : 'inherit',
                transform:
                  isTranslating && index === cursorPosition
                    ? 'scale(1.1)'
                    : 'scale(1)',
              }}
            >
              {char}
            </span>
          );
        })}
    </span>
  );
};

export default TypewriterEffect;
