// src/hooks/useInvitations.ts
import { useState, useEffect, useCallback, useRef } from 'react';
import { useChatContext } from 'stream-chat-react';
import { supabase } from '../supabaseClient';
import { StreamChat } from 'stream-chat';

export interface Invitation {
  id: string;
  inviter_id: string;
  invitee_email: string;
  status: 'pending' | 'accepted' | 'rejected';
  created_at: string;
  updated_at: string;
  accepted_at: string | null;
  rejected_at: string | null;
  channel_id: string | null; // Ensure this field is included
}

export const useInvitations = () => {
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { client } = useChatContext();
  const fetchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fetchInvitations = useCallback(async () => {
    if (!client.userID) {
      setError('User not authenticated');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase
        .from('invitations')
        .select('*')
        .or(
          `inviter_id.eq.${client.userID},invitee_email.eq.${client.user?.email}`,
        )
        .order('created_at', { ascending: false });

      if (error) throw error;
      setInvitations(data);
    } catch (error) {
      console.error('Error fetching invitations:', error);
      setError('Failed to fetch invitations. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [client.userID]);

  const refreshInvitations = useCallback(() => {
    if (fetchTimeoutRef.current) {
      clearTimeout(fetchTimeoutRef.current);
    }
    fetchTimeoutRef.current = setTimeout(() => {
      fetchInvitations();
    }, 300); // 300ms debounce
  }, [fetchInvitations]);

  const sendInvitation = useCallback(
    async (email: string) => {
      if (!client.userID) {
        throw new Error('User not authenticated');
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/invite`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email,
              inviterId: client.userID,
            }),
          },
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to send invitation');
        }

        const data = await response.json();
        refreshInvitations();

        // Generate the invitation URL
        const invitationUrl = `${window.location.origin}/invite`;

        return {
          success: true,
          message: data.message,
          invitationUrl,
          otplink: data.otplink,
        };
      } catch (error) {
        console.error('Error sending invitation:', error);
        return {
          success: false,
          message:
            error instanceof Error
              ? error.message
              : 'Failed to send invitation',
        };
      }
    },
    [client.userID, refreshInvitations],
  );

  const updateInvitationStatus = useCallback(
    async (invitationId: string, status: 'accepted' | 'rejected') => {
      setIsLoading(true);
      setError(null);

      try {
        const updateData = {
          status,
          updated_at: new Date().toISOString(),
          [status === 'accepted' ? 'accepted_at' : 'rejected_at']:
            new Date().toISOString(),
        };

        const { data, error } = await supabase
          .from('invitations')
          .update(updateData)
          .eq('id', invitationId)
          .select();

        if (error) throw error;

        setInvitations((prev) =>
          prev.map((inv) => (inv.id === invitationId ? data[0] : inv)),
        );
        refreshInvitations(); // Refresh invitations after updating
        return { success: true };
      } catch (error) {
        console.error(`Error ${status} invitation:`, error);
        setError(`Failed to ${status} invitation. Please try again.`);
        return { success: false, message: `Failed to ${status} invitation` };
      } finally {
        setIsLoading(false);
      }
    },
    [refreshInvitations],
  );

  const joinChannel = useCallback(
    async (channelId: string) => {
      if (!client.userID) {
        throw new Error('User not authenticated');
      }

      try {
        const channel = client.channel('messaging', channelId);
        await channel.watch();
        return channel;
      } catch (error) {
        console.error('Error joining channel:', error);
        throw error;
      }
    },
    [client],
  );

  useEffect(() => {
    refreshInvitations();
    return () => {
      if (fetchTimeoutRef.current) {
        clearTimeout(fetchTimeoutRef.current);
      }
    };
  }, [refreshInvitations]);

  return {
    invitations,
    isLoading,
    error,
    refreshInvitations,
    sendInvitation,
    updateInvitationStatus,
    joinChannel,
  };
};
