'use client';

import { useState } from 'react';
import { Bell } from 'lucide-react';
import axios from 'axios';
import { Button } from '../../@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../@/components/ui/dialog';
import { getApiUrl } from '../../utils/environment';
import { useToast } from '../../hooks/useToast';

export default function AISupport({
  supportPersona,
  handleToggleSupportPersona,
  username,
  channelId,
}: {
  supportPersona: boolean;
  handleToggleSupportPersona: (value?: boolean) => void;
  username?: string;
  channelId?: string;
}) {
  const { success } = useToast();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleContinueWithAI = () => {
    console.log('Continuing with AI chat');
    setDialogOpen(false);
  };

  const handleCallHuman = async () => {
    if (!channelId) return;
    console.log('Calling a human');
    handleToggleSupportPersona(false);
    setDialogOpen(false);

    const apiUrl = getApiUrl();

    await axios.post(`${apiUrl}/call-human`, {
      username,
      channelId,
    });

    success('A human will be with you shortly!');
  };

  const toggleSupportPersona = () => {
    handleToggleSupportPersona(!supportPersona);
  };

  return (
    <div className="flex items-center justify-center">
      <button
        onClick={toggleSupportPersona}
        className={`text-4xl transition-all duration-300 ${
          supportPersona ? '' : 'opacity-30 grayscale'
        } hover:scale-110 focus:outline-none`}
        aria-label={supportPersona ? 'Disable AI Support' : 'Enable AI Support'}
      >
        ✨
      </button>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogTrigger asChild>
          <Button
            className={`transition-all duration-300 ${
              supportPersona
                ? 'bg-[#4285f4] text-white hover:bg-[#3367d6]'
                : 'bg-gray-400 text-gray-700 hover:bg-gray-500 cursor-not-allowed'
            }`}
            disabled={!supportPersona}
          >
            {supportPersona ? 'AI Enabled' : 'AI Disabled'}
          </Button>
        </DialogTrigger>
        <DialogContent className="bg-[#2a2a2a] border-0 text-white sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-white text-xl font-semibold">
              AI Support
            </DialogTitle>
            <DialogDescription className="text-gray-400">
              This chat is powered by AI. We&apos;d love to talk if we can help
              though.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="sm:justify-start gap-4 mt-4">
            <Button
              type="button"
              className="bg-[#4285f4] text-white hover:bg-[#3367d6] flex-1"
              onClick={handleContinueWithAI}
            >
              Continue Chatting with AI
            </Button>
            <Button
              type="button"
              variant="outline"
              className="bg-white text-[#4285f4] border-white hover:bg-[#4CAF50] hover:text-white hover:border-[#4CAF50] transition-colors duration-200 flex-1"
              onClick={handleCallHuman}
            >
              <Bell className="mr-2 h-4 w-4" />
              Call a Human
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
