import React from 'react';

const ButtonSkeleton = () => {
  return (
    <div className="">
      <div className="h-10 bg-gray-400 rounded"></div>
    </div>
  );
};

export default ButtonSkeleton;
