const InputSkeleton = () => {
  return (
    <div className="space-y-2">
      <div className="h-4 bg-gray-400 rounded w-1/4"></div>
      <div className="h-10 bg-gray-400 rounded"></div>
    </div>
  );
};

export default InputSkeleton;
