import React, { createContext, useState, useContext, ReactNode } from 'react';
import { useSessionStorage } from 'react-use';

type ChatLanguageContextType = {
  chatLanguage: string;
  setChatLanguage: (lang: string) => void;
};

const ChatLanguageContext = createContext<ChatLanguageContextType | undefined>(
  undefined,
);

export const useChatLanguage = () => {
  const context = useContext(ChatLanguageContext);
  if (context === undefined) {
    throw new Error(
      'useChatLanguage must be used within a ChatLanguageProvider',
    );
  }
  return context;
};

export const ChatLanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [chatLanguage, setChatLanguage] = useSessionStorage('language', '');

  return (
    <ChatLanguageContext.Provider value={{ chatLanguage, setChatLanguage }}>
      {children}
    </ChatLanguageContext.Provider>
  );
};
