import './TypingIndicator.css';

export const ControlledTypingIndicator = ({
  isTyping,
}: {
  isTyping: string | null;
}) => {
  const text = 'Someone is typing  ';

  return (
    <div className="messaging__typing-indicator">
      <div className="mr-2">{text}</div>
      {isTyping && (
        <div className="dots">
          <span className="dot" />
          <span className="dot" />
          <span className="dot" />
        </div>
      )}
    </div>
  );
};
