import { AnimatePresence, motion } from 'framer-motion';
import { ArrowLeft, Globe, Loader2, LogIn, Mail, User } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './Auth.css';
import { useToast } from './hooks/useToast';
import { supabase } from './supabaseClient';
// Assuming you have these UI components available in your project
import { Button } from './@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './@/components/ui/dropdown-menu';
import { Input } from './@/components/ui/input';
import { delay } from './@/lib/utils';

export default function Auth() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState(
    localStorage.getItem('language') || 'en',
  );
  const navigate = useNavigate();
  const toast = useToast();
  const [loadingButton, setLoadingButton] = useState<string | null>(null);

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, [language, i18n]);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoadingButton('email');

    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo:
          process.env.REACT_APP_FRONTEND_URL + '/complete-profile',
      },
    });

    if (error) {
      toast.error(error.message);
    } else {
      toast.success(t('confirmationMessage'));
      setIsSubmitted(true);
    }
    setLoadingButton(null);
  };

  const handleReturn = () => {
    setIsSubmitted(false);
    setEmail('');
  };

  const handleGoogleLogin = async () => {
    setLoadingButton('google');
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: process.env.REACT_APP_FRONTEND_URL + '/complete-profile',
      },
    });

    if (error) {
      toast.error(error.message);
      setLoadingButton(null);
    }
  };

  const handleHardcodedLogin = async () => {
    setLoadingButton('hardcoded');
    const { data, error } = await supabase.auth.signInWithPassword({
      email: 'test@test.com',
      password: 'test@test.com',
    });

    const {
      data: { session },
      error: sessionError,
    } = await supabase.auth.getSession();

    await delay(1000);

    if (error) {
      toast.error(error.message);
    } else if (data.user) {
      toast.success(t('Login success'));
      navigate('/complete-profile');
    }
    setLoadingButton(null);
  };

  return (
    <div
      className="min-h-[90vh] flex items-center justify-center bg-cover bg-center bg-no-repeat w-full h-screen"
      style={{
        backgroundImage: 'url("/earth-bg.jpg")',
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="w-full max-w-md p-8 backdrop-blur-xl bg-gray-800/30 rounded-2xl shadow-2xl overflow-hidden border border-gray-700 -mt-20"
      >
        <div className="flex justify-between items-center mb-6">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1, duration: 0.5 }}
            className="text-3xl font-extrabold text-white"
          >
            {t('title')}
          </motion.h2>
          <LanguageSelector language={language} setLanguage={setLanguage} />
        </div>
        <AnimatePresence mode="wait">
          {!isSubmitted ? (
            <motion.div
              key="email-form"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <motion.p
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-center text-gray-300 mb-6"
              >
                {t('subtitle')}
              </motion.p>
              <form onSubmit={handleLogin} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-300 mb-2"
                  >
                    {t('emailLabel')}
                  </label>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={t('emailPlaceholder')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 bg-gray-700/50 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-white placeholder-gray-400"
                    required
                  />
                </div>
                <Button
                  type="submit"
                  disabled={loadingButton !== null}
                  className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-gray-800 text-white transition-colors duration-200 ease-in-out rounded-md font-semibold"
                >
                  {loadingButton === 'email' ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      {t('submittingButton')}
                    </>
                  ) : (
                    <>
                      <Mail className="mr-2 h-4 w-4" />
                      {t('submitButton')}
                    </>
                  )}
                </Button>
                <div className="relative py-4">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t border-gray-600"></span>
                  </div>
                </div>
                <Button
                  type="button"
                  onClick={handleGoogleLogin}
                  disabled={loadingButton !== null}
                  className="w-full py-2 px-4 bg-white hover:bg-gray-100 focus:ring-gray-500 focus:ring-offset-gray-800 text-gray-900 transition-colors duration-200 ease-in-out rounded-md font-semibold flex items-center justify-center"
                >
                  {loadingButton === 'google' ? (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    <LogIn className="mr-2 h-4 w-4" />
                  )}
                  Sign in with Google
                </Button>
                {process.env.NODE_ENV === 'development' && (
                  <Button
                    type="button"
                    onClick={handleHardcodedLogin}
                    disabled={loadingButton !== null}
                    className="w-full py-2 px-4 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-gray-800 text-white transition-colors duration-200 ease-in-out rounded-md font-semibold flex items-center justify-center"
                  >
                    {loadingButton === 'hardcoded' ? (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    ) : (
                      <User className="mr-2 h-4 w-4" />
                    )}
                    Login with Hardcoded Credentials
                  </Button>
                )}
              </form>
            </motion.div>
          ) : (
            <motion.div
              key="confirmation"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="text-center"
            >
              <p className="text-lg mb-6 text-white">
                {t('confirmationMessage')}
              </p>
              <Button
                onClick={handleReturn}
                className="py-2 px-4 bg-gray-700 hover:bg-gray-600 focus:ring-gray-500 focus:ring-offset-gray-800 text-white transition-colors duration-200 ease-in-out rounded-md font-semibold"
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                {t('returnButton')}
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}

function LanguageSelector({
  language,
  setLanguage,
}: {
  language: string;
  setLanguage: (lang: 'en' | 'ja') => void;
}) {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang: 'en' | 'ja') => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="text-white hover:bg-gray-700/50">
          <Globe className="mr-2 h-4 w-4" />
          {language === 'en' ? 'EN' : 'JP'}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="bg-gray-800/90 backdrop-blur-md border border-gray-700"
      >
        <DropdownMenuItem
          onClick={() => changeLanguage('en')}
          className="text-white hover:bg-gray-700/50 cursor-pointer focus:bg-gray-700/50"
        >
          English
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => changeLanguage('ja')}
          className="text-white hover:bg-gray-700/50 cursor-pointer focus:bg-gray-700/50"
        >
          日本語
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
