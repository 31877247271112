import React, {MouseEvent} from "react";

interface IProps {
  onClick: (event: MouseEvent) => unknown;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const Button = ({onClick, children, className = '', disabled = false}: IProps) => {
 return (
    <button
      className={`address-book-link px-4 py-2 flex gap-2 items-center leading-6 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
        ${className}
        ${disabled ? 'bg-gray-600 hover:bg-gray-600 cursor-not-allowed' : ''}
    `}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
