import React, { useState, useEffect } from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';
import type { UserResponse } from 'stream-chat';
import { StreamChatGenerics } from '../../types';
import { supabase } from '../../supabaseClient'; // Assuming you have this set up
import './CreateChannel.css';

type Props = {
  onClose: () => void;
};

const CreateChannel: React.FC<Props> = ({ onClose }) => {
  const { client, setActiveChannel } = useChatContext<StreamChatGenerics>();
  const [selectedUsers, setSelectedUsers] = useState<
    UserResponse<StreamChatGenerics>[]
  >([]);
  const [availableUsers, setAvailableUsers] = useState<
    UserResponse<StreamChatGenerics>[]
  >([]);
  const [groupName, setGroupName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchConnectedUsers = async () => {
      setIsLoading(true);
      if (!client.userID) {
        console.log('No client.userID found');
        return;
      }
      console.log('Fetching connected users for userID:', client.userID);

      // Fetch contacts
      const { data: contacts, error: contactsError } = await supabase
        .from('contacts')
        .select('contact_id')
        .eq('user_id', client.userID);

      console.log('Contacts query result:', { contacts, contactsError });

      // Fetch invitations
      const { data: invitations, error: invitationsError } = await supabase
        .from('invitations')
        .select('invitee_email, inviter_id')
        .or(`inviter_id.eq.${client.userID},invitee_email.eq.${client.userID}`)
        .eq('status', 'accepted');

      console.log('Invitations query result:', {
        invitations,
        invitationsError,
      });

      if (contactsError || invitationsError) {
        console.error(
          'Error fetching connected users:',
          contactsError || invitationsError,
        );
        setIsLoading(false);
        return;
      }

      // Combine and deduplicate user IDs
      const connectedUserIds = new Set([
        ...(contacts?.map((c) => c.contact_id) || []),
        ...(invitations?.map((i) =>
          i.inviter_id === client.userID ? i.invitee_email : i.inviter_id,
        ) || []),
      ]);

      console.log('Combined connected user IDs:', Array.from(connectedUserIds));

      // Check if there are any connected users
      if (connectedUserIds.size === 0) {
        console.log('No connected users found');
        setAvailableUsers([]);
        setIsLoading(false);
        return;
      }

      // Fetch user details from Stream Chat
      try {
        const response = await client.queryUsers(
          { id: { $in: Array.from(connectedUserIds) } },
          { id: 1 },
          { limit: 100 },
        );
        setAvailableUsers(response.users);
      } catch (error) {
        console.error('Error querying users from Stream Chat:', error);
        setAvailableUsers([]);
      }

      setIsLoading(false);
    };

    fetchConnectedUsers();
  }, [client]);

  const toggleUser = (user: UserResponse<StreamChatGenerics>) => {
    setSelectedUsers((prevUsers) => {
      if (prevUsers.some((u) => u.id === user.id)) {
        return prevUsers.filter((u) => u.id !== user.id);
      } else {
        return [...prevUsers, user];
      }
    });
  };

  const createChannel = async () => {
    if (selectedUsers.length === 0) return;

    const members = [
      ...selectedUsers.map((user) => user.id),
      client.userID as string,
    ];

    // Sort members to ensure consistent order
    members.sort();

    // Check if it's a single chat (2 members including the current user)
    if (members.length === 2) {
      // Try to find an existing channel with these two members
      const existingChannels = await client.queryChannels({
        type: 'messaging',
        members: { $eq: members },
      });

      if (existingChannels.length > 0) {
        // If a channel already exists, set it as active and close the modal
        setActiveChannel(existingChannels[0]);
        onClose();
        return;
      }
    }

    // Create a hash of the sorted member IDs
    const memberHash = await createMemberHash(members);

    const channel = client.channel('messaging', memberHash, {
      name:
        groupName ||
        (members.length === 2 ? '' : `Group Chat (${members.length})`),
      members: members,
    });

    try {
      await channel.create();
      setActiveChannel(channel);
      onClose();
    } catch (error) {
      console.error('Error creating channel:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  // Helper function to create a hash of member IDs
  const createMemberHash = async (members: string[]): Promise<string> => {
    const memberString = members.join(',');
    const encoder = new TextEncoder();
    const data = encoder.encode(memberString);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
      .slice(0, 64);
  };

  return (
    <div className="create-channel-overlay">
      <div className="create-channel-modal">
        <h2>Create a New Channel</h2>
        <input
          type="text"
          placeholder="Group Name (optional)"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className="group-name-input"
        />
        <div className="selected-users">
          {selectedUsers.map((user) => (
            <div key={user.id} className="user-pill">
              <span>{user.name || user.id}</span>
              <button onClick={() => toggleUser(user)}>&times;</button>
            </div>
          ))}
        </div>
        <div className="user-list">
          {isLoading ? (
            <div className="loading">Loading connected users...</div>
          ) : (
            availableUsers.map((user) => (
              <div
                key={user.id}
                className={`user-item ${selectedUsers.some((u) => u.id === user.id) ? 'selected' : ''}`}
                onClick={() => toggleUser(user)}
              >
                <Avatar image={user.image} name={user.name || user.id} />
                <span>{user.name || user.id}</span>
                {selectedUsers.some((u) => u.id === user.id) && (
                  <span className="checkmark">✓</span>
                )}
              </div>
            ))
          )}
        </div>
        <div className="button-group">
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
          <button onClick={createChannel} className="create-button">
            Create Channel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateChannel;
