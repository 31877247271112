import React, { useEffect, useMemo } from 'react';
import { TranslationLanguages } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { useChatLanguage } from '../../context/ChatLanguageContext';
import { StreamChatGenerics } from '../../types';
import Dropdown from '../shared/Dropdown';

const determineLanguage = (language: string) => {
  switch (language) {
    case 'original':
      return undefined;

    case 'EN':
      return 'en';

    case 'JA':
      return 'ja';

    default:
      return undefined;
  }
};

const ChatLanguageSelector: React.FC = () => {
  const { chatLanguage, setChatLanguage } = useChatLanguage();
  const { client } = useChatContext<StreamChatGenerics>();

  /*   useEffect(() => {
    if (client.user?.language) {
      let newLanguage = 'original';
      if (client.user.language === 'en') {
        newLanguage = 'EN';
      } else if (client.user.language === 'ja') {
        newLanguage = 'JA';
      }

      setChatLanguage(newLanguage);
    }
  }, [client.user?.language]); */

  const handleLanguageChange = (value: string) => {
    setChatLanguage(value);
  };

  const options = useMemo(
    () => [
      { value: 'original', label: 'Original' },
      { value: 'EN', label: '🇬🇧 EN' },
      { value: 'JA', label: '🇯🇵 JP' },
      { value: 'EN-AI', label: 'EN-AI ⭐' },
      { value: 'JA-AI', label: 'JP-AI ⭐' },
    ],
    [],
  );

  return (
    <Dropdown
      options={options}
      onSelect={handleLanguageChange}
      selectedOption={chatLanguage}
      className="bg-[#858688]"
      showSelectedItem={false}
    />
  );
};

export default ChatLanguageSelector;
