import React, { useMemo } from 'react';
import type { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import { Chat } from 'stream-chat-react';
import CompleteProfile from './components/CompleteProfile';
import { LanguageProvider } from './context/LanguageContext';
import Auth from './Auth';
import AcceptInvitation from './components/AcceptInvitation';
import { ChatLanguageProvider } from './context/ChatLanguageContext';

import data from '@emoji-mart/data';
import { init } from 'emoji-mart';

import 'stream-chat-react/dist/css/v2/index.css';
import './styles/index.css';

import { useThemeContext } from './context';

import {
  useConnectUser,
  useChecklist,
  useUpdateAppHeightOnResize,
} from './hooks';

import type { StreamChatGenerics } from './types';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import LoginDevice from './components/LoginDevice/LoginDevice';
import AddressBook from './components/AddressBook/AddressBook';
import { IconType } from 'react-icons';
import MainContent from './components/MainContent';

import { getFrontendUrl } from './utils/environment';
import LoadingSpinner from './components/LoadingSpinner';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // You'll need to create this file
import Settings from './components/Settings';

init({ data });

export interface ChannelListOptions {
  options: ChannelOptions;
  filters: ChannelFilters;
  sort: ChannelSort;
}

type AppProps = {
  apiKey: string;
  userToConnect: { id: string; name?: string; image?: string; role: string };
  userToken: string | undefined;
  channelListOptions: ChannelListOptions;
  targetOrigin: string;
};

export interface MenuItem {
  id: string;
  icon: IconType;
  label: string;
}

const App = React.memo((props: AppProps) => {
  const { apiKey, userToConnect, userToken, channelListOptions, targetOrigin } =
    props;

  const memoizedProps = useMemo(
    () => ({
      apiKey,
      userToConnect,
      userToken,
    }),
    [apiKey, userToConnect, userToken],
  );

  const chatClient = useConnectUser<StreamChatGenerics>(
    memoizedProps.apiKey,
    memoizedProps.userToConnect,
    memoizedProps.userToken,
  );
  const { themeClassName } = useThemeContext();
  useChecklist(chatClient, getFrontendUrl());
  useUpdateAppHeightOnResize();

  if (!chatClient) {
    return <LoadingSpinner />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <LanguageProvider>
        <ChatLanguageProvider>
          <Chat client={chatClient} theme={`messaging ${themeClassName}`}>
            <Routes>
              <Route
                path="/"
                element={
                  <MainContent
                    role={userToConnect.role}
                    channelListOptions={channelListOptions}
                  />
                }
              />
              <Route path="/complete-profile" element={<CompleteProfile />} />
              <Route path="/login" element={<Auth />} />
              <Route
                path="/invite/:invitationId"
                element={<AcceptInvitation />}
              />
              <Route path="/login-device" element={<LoginDevice />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Chat>
        </ChatLanguageProvider>
      </LanguageProvider>
    </I18nextProvider>
  );
});

export default App;
