import { useEffect, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const AcceptInvitation = () => {
  const { invitationId } = useParams();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { client } = useChatContext();
  const navigate = useNavigate();

  useEffect(() => {
    const acceptInvitation = async () => {
      if (!client.user) {
        setMessage('User is not authenticated');
        return;
      }

      if (!invitationId) {
        setMessage('Invalid invitation ID');
        return;
      }

      setLoading(true);

      try {
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('id')
          .eq('supabase_user_id', client.user.id)
          .single();

        if (profileError) throw new Error('Failed to fetch user profile');
        if (!profile) throw new Error('User profile not found');

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/invite/accept`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ invitationId, inviteeId: profile.id }),
          },
        );

        if (!response.ok) {
          const errorDetails = await response.json();
          console.error(errorDetails);
          // throw new Error(errorDetails.details || 'Failed to accept invitation');
        }

        const result = await response.json();
        setMessage(result.message);

        // Redirect to the main page or address book after successful acceptance
        setTimeout(() => navigate('/address-book'), 2000);
      } catch (error) {
        if (error instanceof Error) {
          setMessage(`An error occurred: ${error.message}`);
        } else {
          setMessage('An unknown error occurred');
        }
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (invitationId && client.user) {
      acceptInvitation();
    }
  }, [invitationId, client.user, navigate]);

  return (
    <div>
      <h2>Accepting Invitation</h2>
      {loading ? <p>Loading...</p> : <p>{message}</p>}
    </div>
  );
};

export default AcceptInvitation;
