import React from 'react';
import ReactModal from 'react-modal';

// Bind modal to your appElement for accessibility
ReactModal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={title}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <div className="modal-header">
        <h2>{title}</h2>
        <button onClick={onClose}>&times;</button>
      </div>
      <div className="modal-body">{children}</div>
    </ReactModal>
  );
};

export default Modal;
