import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import Button from '../shared/Button';
import logger from '../../utils/logger';
import axios from 'axios';
import { getApiUrl } from '../../utils/environment';
import { useToast } from '../../hooks/useToast';
import { useTranslation } from 'react-i18next';

const LoginDeviceButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const handleLoginDevice = async () => {
    setIsLoading(true);
    try {
      const {
        data: { user },
        error: authError,
      } = await supabase.auth.getUser();

      if (authError || !user || !user.email) {
        throw new Error(t('noAuthenticatedUser'));
      }

      const apiUrl = getApiUrl();
      const response = await axios.post(`${apiUrl}/login-device`, {
        userId: user.id,
        email: user.email,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      logger.info('OTP sent successfully', { userId: user.id });
      toast.success(t('otpSent'));
    } catch (error) {
      logger.error('Error in handleLoginDevice:', error);
      toast.error(
        t('errorSendingOTP', {
          error: error instanceof Error ? error.message : t('unknownError'),
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      className="login-device-button bg-green-800 hover:bg-green-600"
      onClick={handleLoginDevice}
      disabled={isLoading}
    >
      {isLoading ? t('sending') : t('sendEmail')}
    </Button>
  );
};

export default LoginDeviceButton;
