import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '../../App';

interface SidebarProps {
  items: MenuItem[];
  selectedItem: string;
  onChange: (itemId: string) => void;
  toggleMobile: () => void;
  horizontal?: boolean;
}

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2c3e50;
  padding: 10px;
  min-height: 100vh;
  height: 100%;
  width: 55px;
`;

const SidebarItem = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? '#3498db' : '#ecf0f1')};
  background-color: ${(props) => (props.selected ? '#34495e' : 'transparent')};
  transition: all 0.3s ease;

  &:hover {
    background-color: #34495e;
  }
`;

const horizontalStyles: React.CSSProperties = {
  flexDirection: 'row',
  height: '50px',
  minHeight: 'unset',
  width: '100%',
};

const Sidebar: React.FC<SidebarProps> = ({
  items,
  selectedItem,
  onChange,
  toggleMobile,
  horizontal,
}) => {
  return (
    <SidebarContainer
      style={horizontal ? horizontalStyles : undefined}
      id={horizontal ? 'horizontal-sidebar' : 'vertical-sidebar'}
    >
      {/* <div
        id="mobile-nav-icon"
        // className={`${theme}`}
        onClick={() => toggleMobile()}
      >
        <div className="pulse" />
        <HamburgerIcon />
      </div> */}

      {items.map((item) => (
        <SidebarItem
          key={item.id}
          selected={item.id === selectedItem}
          onClick={() => {
            if (item.id === 'chat' && selectedItem === 'chat') {
              toggleMobile();
            } else onChange(item.id);
          }}
          style={{
            height: horizontal ? '35px' : '60px',
          }}
        >
          <item.icon size={horizontal ? 20 : 28} />
        </SidebarItem>
      ))}
    </SidebarContainer>
  );
};

export default Sidebar;
