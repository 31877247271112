import React, { ReactNode } from 'react';

interface Props {
  value: string;
  onChange?: (value: string) => void;
  type?: string;
  Icon?: ReactNode;
  className?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  autoFocus?: boolean;
}

const Input = ({
  value,
  onChange = () => {},
  label,
  type = 'text',
  Icon,
  placeholder = '',
  className = '',
  disabled = false,
  readOnly = false,
  labelClassName = 'block text-sm font-medium mb-2 text-gray-400',
  inputClassName = '',
  autoFocus = false,
}: Props) => {
  const inputId = label
    ? `input-${label?.replace(/\s+/g, '-').toLowerCase() || Math.random().toString(36).slice(2, 11)}`
    : undefined;

  return (
    <div className={`${className}`}>
      {label && (
        <label htmlFor={inputId} className={labelClassName}>
          {label}
        </label>
      )}
      <div className="relative">
        {Icon && (
          <div className="absolute inset-y-0 left-2 pl-3 flex items-center pointer-events-none">
            {Icon}
          </div>
        )}
        <input
          autoFocus={autoFocus}
          id={inputId}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          className={`w-48 md:w-72 bg-neutral-700 text-white px-3 py-3 rounded ${readOnly ? 'focus:outline-none' : ''} ${inputClassName} ${Icon ? 'pl-12' : ''}`}
        />
      </div>
    </div>
  );
};

export default Input;
