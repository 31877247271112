import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import axios from 'axios';
import { getApiUrl } from '../../utils/environment';
import logger from '../../utils/logger';
import { t } from 'i18next';

const LoginDevice: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleLoginDevice = async () => {
      try {
        const { data: sessionData, error: sessionError } =
          await supabase.auth.getSession();

        if (sessionError) throw sessionError;

        if (sessionData.session) {
          navigate('/');
          return;
        }

        const hash = window.location.hash.substring(1);
        const apiUrl = getApiUrl();
        const response = await axios.post(
          `${apiUrl}/exchange-code`,
          { hash },
          {
            // withCredentials: true, // Important for CORS with credentials
          },
        );

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        if (response.data.session) {
          await supabase.auth.setSession(response.data.session);
          navigate('/');
        } else {
          setError(t('failedToAuthenticate'));
        }
      } catch (error) {
        logger.error('LoginDevice: Unexpected error', error);
        setError(t('unexpectedError'));
      } finally {
        setIsLoading(false);
      }
    };

    handleLoginDevice();
  }, [navigate]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return null;
};

export default LoginDevice;
