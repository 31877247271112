import React, { useState, useEffect } from 'react';
import './utils/instrument';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import { Session } from '@supabase/supabase-js';
import ls from 'localstorage-slim';
import App from './App';
import { supabase } from './supabaseClient';
import { getApiUrl } from './utils/environment';
import axios, { AxiosError } from 'axios';
import logger from './utils/logger';
import Auth from './Auth';
import { ToastContainer } from 'react-toastify';
import LoadingSpinner from './components/LoadingSpinner';

// Define the structure of props passed to the main App component
type AppProps = {
  apiKey: string;
  userToConnect: {
    id: string;
    name?: string;
    image?: string;
    role: string;
  };
  userToken: string;
  channelListOptions: {
    filters: ChannelFilters;
    sort: ChannelSort;
    options: ChannelOptions;
  };
  targetOrigin: string;
};

const Router = () => {
  // State variables
  const [appProps, setAppProps] = useState<AppProps | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [session, setSession] = useState<Session | null>(null);

  // Function to fetch necessary data for the app
  const fetchAppProps = async () => {
    try {
      // Get the user session from Supabase
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();

      if (sessionError) throw sessionError;
      if (!session) {
        logger.info('No active session found');
        return;
      }

      // Fetch user profile data
      const { data } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session?.user?.id || '')
        .single();

      setSession(session);

      if (session) {
        const userId = session.user.id;
        logger.info('Fetched user session', { userId });

        // Try to get Stream Chat credentials from localStorage first
        let credentials = getStreamChatCredentialsFromLocalStorage(userId) as {
          streamChatId: string;
          token: string;
          user: any;
        } | null;

        if (credentials) {
          logger.info('Using Stream Chat credentials from localStorage', {
            userId,
            streamChatId: credentials.streamChatId,
          });
        } else {
          logger.info(
            'No Stream Chat credentials found in localStorage, fetching from server',
            { userId },
          );
          // If not found in localStorage, fetch from server
          credentials = await getStreamChatCredentials(userId);
          // Save the credentials to localStorage for future use
          saveStreamChatCredentialsToLocalStorage(userId, credentials);
        }

        if (!credentials) {
          logger.error('Failed to obtain Stream Chat credentials', { userId });
          return;
        }
        const { streamChatId, token, user } = credentials as {
          streamChatId: string;
          token: string;
          user: any;
        };
        logger.info('Using Stream Chat credentials', {
          userId,
          streamChatId,
          tokenLength: token.length,
        });

        // Set up the appProps with fetched data
        setAppProps({
          apiKey: process.env.REACT_APP_STREAM_KEY || '',
          userToConnect: {
            id: streamChatId,
            name: data?.name,
            image: data?.avatar_url,
            role: data?.role,
          },
          userToken: token,
          targetOrigin: window.location.origin,
          channelListOptions: {
            filters: { members: { $in: [streamChatId] } },
            sort: { last_message_at: -1 },
            options: { limit: 10 },
          },
        });
        logger.info('App props set successfully', { userId, streamChatId });
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // Effect to set up auth state change listener and initial data fetch
  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        fetchAppProps();
      }
    });

    fetchAppProps();
  }, []);

  // Helper function to get Stream Chat credentials from localStorage
  const getStreamChatCredentialsFromLocalStorage = (userId: string) => {
    try {
      const storedCredentials = ls.get(`streamChatCredentials_${userId}`, {
        decrypt: true,
      });
      if (storedCredentials) {
        logger.info('Found Stream Chat credentials in localStorage', {
          userId,
        });
        return storedCredentials;
      }
      logger.info('No Stream Chat credentials found in localStorage', {
        userId,
      });
    } catch (error) {
      logger.error(
        'Error reading Stream Chat credentials from localStorage:',
        error,
      );
    }
    return null;
  };

  // Helper function to save Stream Chat credentials to localStorage
  const saveStreamChatCredentialsToLocalStorage = (
    userId: string,
    credentials: any,
  ) => {
    try {
      ls.set(`streamChatCredentials_${userId}`, credentials, { encrypt: true });

      logger.info('Saved Stream Chat credentials to localStorage', { userId });
    } catch (error) {
      logger.error(
        'Error saving Stream Chat credentials to localStorage:',
        error,
      );
    }
  };

  // Helper function to get Stream Chat credentials from the server
  const getStreamChatCredentials = async (userId: string) => {
    const apiUrl = getApiUrl();
    logger.info('Fetching Stream Chat credentials from server', {
      userId,
      apiUrl,
    });
    try {
      const response = await axios.get(
        `${apiUrl}/api/stream-chat-id/${userId}`,
        // { withCredentials: true },
      );
      const { streamChatId, token, user } = response.data;
      if (!streamChatId || !token)
        throw new Error('Invalid response from server');
      logger.info('Successfully fetched Stream Chat credentials from server', {
        userId,
        streamChatId,
      });
      return { streamChatId, token, user };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ error?: string }>;
        logger.error(
          'Axios error fetching Stream Chat credentials:',
          axiosError.response?.data,
        );

        throw new Error(
          `Failed to fetch Stream Chat credentials: ${axiosError.response?.data?.error || axiosError.message}`,
        );
      }
      logger.error('Unknown error fetching Stream Chat credentials:', error);
      throw error;
    }
  };

  // Helper function to handle and log errors
  const handleError = (error: unknown) => {
    const message =
      error instanceof Error ? error.message : 'An unknown error occurred';
    logger.error('Error fetching app props:', message);
    setError(message);
  };

  // Show loading spinner while data is being fetched
  if (loading) {
    return <LoadingSpinner />;
  }

  // Display error message if an error occurs
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Main component return
  return (
    <div className="text-white">
      <BrowserRouter>
        <Routes>
          {/* Login route */}
          <Route path="/login" element={<Auth />} />
          {/* Main app route with authentication check */}
          <Route
            path="/*"
            element={
              session ? (
                appProps ? (
                  <App {...appProps} />
                ) : (
                  <Navigate to="/login" replace />
                )
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
        {/* Toast container for notifications */}
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
};

export default Router;
