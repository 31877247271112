import React, { useState, useCallback } from 'react';
import SuccessModal from './SuccessModal';
import { useInvitations } from '../../hooks/useInvitations';
import Modal from '../Modal';
import { useToast } from '../../hooks/useToast';
import Input from '../shared/Input';
import { useTranslation } from 'react-i18next';

interface InviteFriendsProps {
  onError: (errorMessage: string) => void;
  onClose: () => void;
  onInviteSent: (inviteUrl: string) => void;
  refreshInvitations?: () => void;
  isOverlay?: boolean;
}

const InviteFriends: React.FC<InviteFriendsProps> = ({
  onError,
  onClose,
  onInviteSent,
  refreshInvitations,
}) => {
  const [email, setEmail] = useState('');
  const [showInput, setShowInput] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [inviteUrl, setInviteUrl] = useState('');
  const [isInviting, setIsInviting] = useState(false);

  const { sendInvitation } = useInvitations();
  const toast = useToast();
  const { t } = useTranslation();

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const inviteFriend = useCallback(async () => {
    if (!email || !validateEmail(email)) {
      console.log('Invalid email:', email);
      toast.error(t('invalidEmail'));
      return;
    }

    setIsInviting(true);
    console.log('Attempting to send invitation to:', email);

    try {
      const result = await sendInvitation(email);

      if (result.success) {
        setShowSuccessModal(true);
        setShowInput(false);
        setEmail('');
        onInviteSent('test!!!');
        setInviteUrl(result.otplink);

        setTimeout(() => {
          refreshInvitations && refreshInvitations();
        }, 2000);
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error in inviteFriend:', error);
      const errorMsg =
        error instanceof Error ? error.message : t('unknownError');
      toast.error(errorMsg);
      onError(errorMsg);
    } finally {
      setIsInviting(false);
    }
  }, [
    email,
    onError,
    onInviteSent,
    refreshInvitations,
    sendInvitation,
    toast,
    t,
  ]);

  const handleChange = (email: string) => {
    setEmail(email);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    inviteFriend();
  };

  const handleSuccessModalDismiss = () => {
    setShowSuccessModal(false);
    setInviteUrl('');
    refreshInvitations && refreshInvitations();
    onClose();
  };

  const content = (
    <div className="text-white p-0 sm:p-6">
      {showInput && (
        <form onSubmit={handleSubmit}>
          <Input
            autoFocus
            type="email"
            placeholder={t('enterFriendEmail')}
            value={email}
            onChange={handleChange}
            className="mb-5"
            inputClassName="w-full"
          />
          <div className="flex gap-2 flex-col md:flex-row">
            <button
              type="submit"
              disabled={isInviting}
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
            >
              {isInviting ? (
                <>
                  <span
                    className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full"
                    role="status"
                    aria-label="loading"
                  ></span>
                  {t('inviting')}
                </>
              ) : (
                t('inviteFriend')
              )}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-gray-300 font-semibold bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-all text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600 dark:focus:ring-offset-gray-800"
            >
              {t('cancel')}
            </button>
          </div>
        </form>
      )}
      {showSuccessModal && (
        <SuccessModal
          mainMessage={t('invitationSent')}
          subMessage={
            inviteUrl
              ? t('invitationSentWithLink')
              : t('invitationSentWithoutLink')
          }
          onDismiss={handleSuccessModalDismiss}
          inviteUrl={inviteUrl}
        />
      )}
    </div>
  );

  return (
    <Modal isOpen={true} onClose={onClose}>
      {content}
    </Modal>
  );
};

export default InviteFriends;
