import { useChatContext, useTypingContext } from 'stream-chat-react';

import './TypingIndicator.css';

import type { StreamChatGenerics } from '../../types';
import { SUPPORT_STAFF_ID } from '../../@/lib/constants';

export const MessageListTypingIndicator = () => {
  const { client } = useChatContext<StreamChatGenerics>();

  const { typing } = useTypingContext<StreamChatGenerics>();
  if (!client || !typing || !Object.values(typing).length) return null;

  const users = Object.values(typing)
    .filter(({ user }) => user?.id !== client.user?.id)
    .map(({ user }) => ({ id: user?.id, name: user?.name }));

  let text = '';

  if (users.length === 1) {
    text = `${users[0].name} is typing`;
  } else if (users.length === 2) {
    text = `${users[0].name} and ${users[1].name} are typing`;
  } else if (users.length > 2) {
    text = `${users[0].name} and ${users.length - 1} more are typing`;
  }

  if (users.length === 0) return null;
  if (users[0].id === SUPPORT_STAFF_ID) return null;

  return (
    <div
      className="str-chat__typing-indicator str-chat__typing-indicator--typing"
      id="bottom-typing-indicator"
      data-testid="typing-indicator"
    >
      <div className="str-chat__typing-indicator__dots">
        <span className="str-chat__typing-indicator__dot" />
        <span className="str-chat__typing-indicator__dot" />
        <span className="str-chat__typing-indicator__dot" />
      </div>
      <div
        className="str-chat__typing-indicator__users"
        data-testid="typing-users"
      >
        {text && (
          <div className="dots">
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </div>
        )}
        <div>{text}</div>
      </div>
    </div>
  );
};
