import { useMemo } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';

// Update the Table component to accept props
const Table = <T extends Record<string, any>>({
  data,
  columns,
}: {
  data: T[];
  columns: MRT_ColumnDef<T>[];
}) => {
  const table = useMantineReactTable({
    columns,
    data,
    mantineTableProps: {
      striped: true,
    },
    initialState: {
      pagination: {
        pageSize: 50, // Increase this number to show more rows per page
        pageIndex: 0,
      },
      // Add initial sorting state
      sorting: [
        {
          id: 'id', // Replace with your actual date or ID column
          desc: true, // Set to true for descending order (newest first)
        },
      ],
    },
    // Enable sorting feature
    enableSorting: true,
  });

  return <MantineReactTable table={table} />;
};

export default Table;
