import {
  ChannelPreviewUIComponentProps,
  ChatContextValue,
  useChatContext,
} from 'stream-chat-react';
import { AvatarGroup } from '../';
import './MessagingChannelPreview.css';

import { motion } from 'framer-motion';
import { MouseEventHandler, useEffect, useState } from 'react';
import type { Channel, ChannelMemberResponse } from 'stream-chat';
import type { StreamChatGenerics } from '../../types';
import { ControlledTypingIndicator } from '../TypingIndicator/ControlledTypingIndicator';

const getTimeStamp = (channel: Channel) => {
  let lastHours = channel.state.last_message_at?.getHours();
  let lastMinutes: string | number | undefined =
    channel.state.last_message_at?.getMinutes();
  let half = 'AM';

  if (lastHours === undefined || lastMinutes === undefined) {
    return '';
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12;
    half = 'PM';
  }

  if (lastHours === 0) lastHours = 12;
  if (lastHours === 12) half = 'PM';

  if (lastMinutes.toString().length === 1) {
    lastMinutes = `0${lastMinutes}`;
  }

  return `${lastHours}:${lastMinutes} ${half}`;
};

const getChannelName = (members: ChannelMemberResponse[]) => {
  const defaultName = 'HashiDefault';

  if (!members.length || members.length === 1) {
    return members[0]?.user?.name || defaultName;
  }

  return `${members[0]?.user?.name || defaultName}, ${members[1]?.user?.name || defaultName}`;
};

type MessagingChannelPreviewProps = ChannelPreviewUIComponentProps & {
  channel: Channel;
  onClick: MouseEventHandler;
  setActiveChannel?: ChatContextValue['setActiveChannel'];
};

const MessagingChannelPreview = (props: MessagingChannelPreviewProps) => {
  const [isTyping, setIsTyping] = useState<string | null>(null);
  const { channel, setActiveChannel, onClick, latestMessage } = props;
  const { channel: activeChannel, client } =
    useChatContext<StreamChatGenerics>();

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user?.id !== client.userID,
  );

  const handleChannelTyping = (e: any) => {
    if (e.user.id === client.userID) return;
    setIsTyping(e.user.id);
  };

  const handleChannelTypingStop = () => {
    setIsTyping(null);
  };

  useEffect(() => {
    channel.on('typing.start', handleChannelTyping);
    channel.on('typing.stop', handleChannelTypingStop);

    return () => {
      channel.off('typing.start', handleChannelTyping);
      channel.off('typing.stop', handleChannelTypingStop);
    };
  }, [channel]);

  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? 'channel-preview__container selected'
          : 'channel-preview__container'
      }
      onClick={(e) => {
        onClick(e);
        setActiveChannel?.(channel);
      }}
    >
      <AvatarGroup members={members} />
      <div className="channel-preview__content-wrapper">
        <div className="channel-preview__content-top">
          <p className="channel-preview__content-name">
            {channel.data?.name || getChannelName(members)}
          </p>
          <p className="channel-preview__content-time">
            {getTimeStamp(channel)}
          </p>
        </div>
        <div className="channel-preview__content-message">
          {isTyping ? (
            <motion.div
              key="controlled"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <ControlledTypingIndicator isTyping={isTyping} />
            </motion.div>
          ) : (
            <motion.p
              key="latestMessage"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {latestMessage}
            </motion.p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessagingChannelPreview;
