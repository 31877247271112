import { SearchIndex } from 'emoji-mart';
import { useEffect, useRef, useState } from 'react';
import { useSessionStorage } from 'react-use';
import {
  Channel,
  MessageTimestamp,
  TypingIndicator,
  useChatContext,
  useMessageContext,
} from 'stream-chat-react';
import { SUPPORT_STAFF_ID } from '../@/lib/constants';
import { ChannelListOptions } from '../App';
import { GiphyContextProvider, useThemeContext } from '../context';
import { useMobileView } from '../hooks';
import { useInvitations } from '../hooks/useInvitations';
import AddressBook from './AddressBook/AddressBook';
import Admin from './Admin';
import AppSidebar from './AppSidebar';
import {
  ChannelInner,
  MessagingSidebar,
  MessagingThreadHeader,
  SendButton,
} from './index';
import Settings from './Settings';
import WrappedEmojiPicker from './WrappedEmojiPicker';

interface Props {
  channelListOptions: ChannelListOptions;
  role: string;
}

const CustomTimestamp = () => {
  const { message } = useMessageContext();
  const { client } = useChatContext();

  const isAdminMessage = message.pinned_by?.id === SUPPORT_STAFF_ID;
  const isOwnMessage = message.user?.id === client.userID;

  return (
    <>
      <MessageTimestamp />
      {!message.translationError &&
        !message.translationSuccess &&
        !isAdminMessage &&
        !isOwnMessage && (
          <svg id="spinner" viewBox="25 25 50 50">
            <circle r={20} cy={50} cx={50} />
          </svg>
        )}
    </>
  );
};

const MainContent = ({ role, channelListOptions }: Props) => {
  const { client, channel } = useChatContext();
  const { toggleMobile, isMobileNavVisible } = useMobileView();
  const { themeClassName } = useThemeContext();

  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setActiveChannelId(channel?.id || null);
  }, [channel]);

  const [isCreating, setIsCreating] = useState(true);
  const [selectedSidebarItem, setSelectedSidebarItem] = useSessionStorage(
    'selected-sidebar-item',
    'chat',
  );
  const [activeChannelId, setActiveChannelId] = useState<string | null>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent): void => {
      if (
        isMobileNavVisible &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        toggleMobile();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isMobileNavVisible, toggleMobile]);

  const {
    invitations,
    isLoading,
    error,
    refreshInvitations,
    sendInvitation,
    updateInvitationStatus,
  } = useInvitations();

  const handleNavigateToChat = (channelId: string) => {
    setActiveChannelId(channelId);
    setSelectedSidebarItem('chat');
  };

  return (
    <>
      <AppSidebar
        selectedItem={selectedSidebarItem}
        setSelectedItem={setSelectedSidebarItem}
        refreshInvitations={refreshInvitations}
        toggleMobile={toggleMobile}
        role={role}
        horizontal
      />

      <div style={{ display: 'flex' }} className="app-container">
        <AppSidebar
          selectedItem={selectedSidebarItem}
          setSelectedItem={setSelectedSidebarItem}
          refreshInvitations={refreshInvitations}
          toggleMobile={toggleMobile}
          role={role}
        />

        {selectedSidebarItem === 'admin' ? (
          <Admin />
        ) : selectedSidebarItem === 'settings' ? (
          <Settings />
        ) : selectedSidebarItem === 'addressBook' ? (
          <div className="app-container w-full">
            <AddressBook
              invitations={invitations}
              isLoading={isLoading}
              error={error}
              refreshInvitations={refreshInvitations}
              sendInvitation={sendInvitation}
              updateInvitationStatus={updateInvitationStatus}
              onNavigateToChat={handleNavigateToChat}
            />
          </div>
        ) : (
          <div className="app-chat-container">
            <MessagingSidebar
              ref={sidebarRef}
              channelListOptions={channelListOptions}
              onClick={toggleMobile}
              onCreateChannel={() => setIsCreating(!isCreating)}
              onPreviewSelect={() => {
                setIsCreating(false);
                toggleMobile();
              }}
              userId={client.userID || ''}
              activeChannelId={activeChannelId}
            />
            <Channel
              // @ts-ignore
              MessageTimestamp={() => <CustomTimestamp />}
              maxNumberOfFiles={10}
              multipleUploads={true}
              SendButton={SendButton}
              ThreadHeader={MessagingThreadHeader}
              TypingIndicator={TypingIndicator}
              EmojiPicker={WrappedEmojiPicker}
              emojiSearchIndex={SearchIndex}
              enrichURLForPreview
              channel={
                activeChannelId
                  ? client.channel('messaging', activeChannelId)
                  : undefined
              }
            >
              <GiphyContextProvider>
                <ChannelInner
                  theme={themeClassName}
                  toggleMobile={toggleMobile}
                />
              </GiphyContextProvider>
            </Channel>
          </div>
        )}
      </div>
    </>
  );
};

export default MainContent;
