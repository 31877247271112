import React, { ReactNode } from 'react';

interface Props {
  value: string;
  onChange?: (value: string) => void;
  // Removed: type?: string;
  // Removed: Icon?: ReactNode;
  className?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  labelClassName?: string;
  textareaClassName?: string;
  autoFocus?: boolean;
  rows?: number;
  cols?: number;
}

const Textarea = ({
  value,
  onChange = () => {},
  label,
  // Removed: type = 'text',
  // Removed: Icon,
  placeholder = '',
  className = '',
  disabled = false,
  readOnly = false,
  labelClassName = 'block text-sm font-medium mb-2 text-gray-400',
  textareaClassName = '',
  autoFocus = false,
  rows = 4,
  cols = 50,
}: Props) => {
  const textareaId = label
    ? `textarea-${label?.replace(/\s+/g, '-').toLowerCase() || Math.random().toString(36).slice(2, 11)}`
    : undefined;

  return (
    <div className={`${className}`}>
      {label && (
        <label htmlFor={textareaId} className={labelClassName}>
          {label}
        </label>
      )}
      <div className="relative">
        <textarea
          autoFocus={autoFocus}
          id={textareaId}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readOnly}
          className={`w-full bg-neutral-700 text-white px-3 py-3 rounded ${readOnly ? 'focus:outline-none' : ''} ${textareaClassName}`}
          rows={rows}
          cols={cols}
        />
      </div>
    </div>
  );
};

export default Textarea;
