import React, { createContext, useState, useContext, useEffect } from 'react';
import { supabase } from '../supabaseClient';

type LanguageContextType = {
  language: string;
  setLanguage: (lang: string) => void;
  fetchUserLanguage: () => Promise<void>;
};

export const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined,
);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState('EN');

  const fetchUserLanguage = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('profiles')
        .select('native_language')
        .eq('supabase_user_id', user.id)
        .maybeSingle(); // Use maybeSingle() to handle zero rows

      if (data && !error) {
        setLanguage(data.native_language);
      }
    }
  };

  useEffect(() => {
    fetchUserLanguage();
  }, []);

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage, fetchUserLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
