import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../hooks/useToast';
import { supabase } from '../supabaseClient';
import './CompleteProfile.css';
import Onboarding from './Onboarding/OnboardingUI';
import { t } from 'i18next';
import LoadingSpinner from './LoadingSpinner';
import { titleCase } from '../@/lib/utils';

const CompleteProfile: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setLanguage, fetchUserLanguage } = useLanguage();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkExistingUser = async () => {
      const {
        data: { user },
        error: authError,
      } = await supabase.auth.getUser();

      if (user) {
        // Get the profile
        const { data: existingProfile, error: existingProfileError } =
          await supabase
            .from('profiles')
            .select('id, is_onboarded')
            .eq('supabase_user_id', user.id)
            .single();
        if (existingProfile && existingProfile.is_onboarded) {
          navigate('/');
          return;
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    checkExistingUser();
  }, []);

  const handleSubmit = async (userData: {
    name: string;
    birthYear: string;
    gender: string;
    profilePicture: File | null;
    language: string;
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { user },
          error: authError,
        } = await supabase.auth.getUser();

        if (authError || !user) {
          // toast.error('Authentication failed. Please sign in again.');
          throw new Error(t('failedToAuthenticate'));
        }

        const profileData = {
          id: user.id,
          supabase_user_id: user.id,
          name: userData.name || user.email,
          native_language: userData.language,
          email: user.email,
          gender: userData.gender || 'unspecified',
          is_onboarded: true,
        };

        const { data, error: upsertError } = await supabase
          .from('profiles')
          .upsert(profileData, { onConflict: 'id' })
          .select('id, supabase_user_id, stream_chat_id')
          .single();

        if (upsertError) {
          console.error('Upsert error:', upsertError);
          toast.error(t('failedToUpdateProfile'));
          throw new Error(t('failedToUpdateProfile'));
        }

        if (!data || !data.id) {
          console.error('No data returned from upsert or missing id:', data);
          toast.error(t('failedToRetrieveProfileData'));
          throw new Error(t('failedToRetrieveProfileData'));
        }

        try {
          await axios.post(`${process.env.REACT_APP_API_URL}/invite/accept`, {
            inviteeId: user.id,
            email: user.email,
            name: userData.name
              ? titleCase(userData.name.toLowerCase())
              : user.email,
            nativeLanguage: userData.language,
            gender: userData.gender || 'unspecified',
          });
        } catch (error) {
          console.error('Error updating invitation:', error);
          // throw error;
        }

        setLanguage(userData.language);
        fetchUserLanguage();
        navigate('/');
        resolve(true);

        // Delete form information
        localStorage.removeItem('onboardingStep');
        localStorage.removeItem('onboardingFormData');
      } catch (error) {
        reject(false);
        console.error('Profile update error:', error);
        if (axios.isAxiosError(error) && error.response) {
          console.error('Axios error response:', error.response.data);
          toast.error(t('somethingWentWrong'));
        } else {
          // toast.error('An unexpected error occurred.');
        }
      }
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return <Onboarding onSubmit={handleSubmit} />;
};

export default CompleteProfile;
