const isVercel = process.env.VERCEL === '1';
const isDevelopment = process.env.NODE_ENV === 'development';

export const getApiUrl = (): string => {
  if (isVercel) {
    return process.env.REACT_APP_API_URL || 'https://api.hashi-chat.app';
  }
  if (isDevelopment) {
    return process.env.REACT_APP_API_URL!;
  }
  return process.env.REACT_APP_API_URL || 'https://api.hashi-chat.app';
};

export const getFrontendUrl = (): string => {
  if (isVercel) {
    return process.env.VERCEL_URL
      ? `https://${process.env.VERCEL_URL}`
      : 'https://hashi-chat.app';
  }
  if (isDevelopment) {
    return 'http://localhost:3000';
  }
  return process.env.REACT_APP_FRONTEND_URL || 'https://hashi-chat.app';
};

// Prevent accidental modification of environment variables
Object.freeze(process.env);
