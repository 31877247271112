const logger = {
  info: (message: string, data?: object) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`[INFO] ${new Date().toISOString()} - ${message}`, data);
    }
  },
  error: (message: string, error?: any) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(`[ERROR] ${new Date().toISOString()} - ${message}`, error);
    }
  },
};

export default logger;
