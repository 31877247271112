import React from 'react';
import { Channel, MessageList, MessageInput } from 'stream-chat-react';
import { Channel as ChannelType } from 'stream-chat';

interface ChatModalProps {
  channel: ChannelType;
  onClose: () => void;
}

const ChatModal: React.FC<ChatModalProps> = ({ channel, onClose }) => {
  return (
    <div className="chat-modal">
      <div className="chat-modal-header">
        <h3>Chat with {channel.data?.name || 'User'}</h3>
        <button onClick={onClose}>Close</button>
      </div>
      <Channel channel={channel}>
        <MessageList />
        <MessageInput />
      </Channel>
    </div>
  );
};

export default ChatModal;