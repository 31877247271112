import React, { useState, useMemo } from 'react';
import {
  FaCog,
  FaComments,
  FaAddressBook,
  FaUserPlus,
  FaBolt,
} from 'react-icons/fa';
import Sidebar from './Sidebar/Sidebar';
import { MenuItem } from '../App';
import { useNavigate } from 'react-router-dom';
import EmailInput from './EmailInput';
import styled from 'styled-components';

interface AppSidebarProps {
  selectedItem: string;
  setSelectedItem: (itemId: string) => void;
  refreshInvitations: () => void;
  toggleMobile: () => void;
  role: string;
  horizontal?: boolean;
}

const SidebarContainer = styled.div`
  position: relative;
`;

const AppSidebar: React.FC<AppSidebarProps> = ({
  selectedItem,
  setSelectedItem,
  refreshInvitations,
  toggleMobile,
  role,
  horizontal,
}) => {
  const navigate = useNavigate();
  const [showEmailInput, setShowEmailInput] = useState(false);

  const menuItems: MenuItem[] = useMemo(
    () =>
      [
        { id: 'chat', icon: FaComments, label: 'Chat' },
        { id: 'addressBook', icon: FaAddressBook, label: 'Address Book' },
        role === 'admin' && {
          id: 'admin',
          icon: FaBolt,
          label: 'Admin tab',
        },
        { id: 'settings', icon: FaCog, label: 'Settings' },
        { id: 'inviteFriend', icon: FaUserPlus, label: 'Invite Friend' },
      ].filter(Boolean) as MenuItem[],
    [role],
  );

  const handleSidebarChange = (itemId: string) => {
    if (itemId === 'inviteFriend') {
      setShowEmailInput(true);
    } else {
      setSelectedItem(itemId);
    }
  };

  const handleCloseEmailInput = () => {
    setShowEmailInput(false);
  };

  const handleError = (errorMessage: string) => {
    console.error(errorMessage);
    // Implement error handling (e.g., show a toast notification)
  };

  const handleInviteSent = () => {
    console.log('Invite sent');
    // Implement any logic you need when an invite is sent
  };

  return (
    <>
      <SidebarContainer className={horizontal ? 'horizontal-sidebar' : ''}>
        <Sidebar
          items={menuItems}
          selectedItem={selectedItem}
          onChange={handleSidebarChange}
          toggleMobile={toggleMobile}
          horizontal={horizontal}
        />
      </SidebarContainer>
      {showEmailInput && (
        <EmailInput
          refreshInvitations={refreshInvitations}
          onClose={handleCloseEmailInput}
          onError={handleError}
          onInviteSent={handleInviteSent}
        />
      )}
    </>
  );
};

export default AppSidebar;
