import { useThemeContext } from '../context';
import { EmojiPicker } from 'stream-chat-react/emojis';
import React from 'react';

const WrappedEmojiPicker = () => {
  const { theme } = useThemeContext();

  return <EmojiPicker pickerProps={{ theme }} />;
};

export default WrappedEmojiPicker;
