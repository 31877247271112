import { MRT_ColumnDef } from 'mantine-react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useToast } from '../hooks/useToast';
import { supabase } from '../supabaseClient';
import Button from './shared/Button';
import Table from './shared/Table';
import Textarea from './shared/Textarea';
import SkeletonLoader from './SkeletonLoader/SkeletonLoader';
import Modal from './shared/Modal';
import '../styles/modal.css';
import dayjs from 'dayjs';

interface LlmCall {
  id: string;
  created_at: string;
  llm_used: string;
  call: string;
  response: string;
}

const Admin: React.FC = () => {
  const [translationPrompt, setTranslationPrompt] = useState<{
    created_at: string;
    id: number;
    prompt: string;
  }>({
    created_at: '',
    id: 0,
    prompt: '',
  });

  const [personaSupportPrompt, setPersonaSupportPrompt] = useState<{
    created_at: string;
    id: number;
    prompt: string;
  }>({
    created_at: '',
    id: 0,
    prompt: '',
  });

  const [llmCalls, setLlmCalls] = useState<LlmCall[]>([]);
  console.log('llmCalls:', llmCalls);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();

  const [selectedCall, setSelectedCall] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('llm_config')
        .select('*')
        .or('title.eq.translation-prompt,title.eq.persona-support');

      const { data: llmData, error: llmError } = await supabase
        .from('llm_call_log')
        .select('*');

      if (error || llmError) {
        throw error || llmError;
      }

      setLlmCalls(llmData);
      setTranslationPrompt(
        data.find((item) => item.title === 'translation-prompt'),
      );
      setPersonaSupportPrompt(
        data.find((item) => item.title === 'persona-support'),
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      // Update translation prompt
      const { error: translationError } = await supabase
        .from('llm_config')
        .update({ prompt: translationPrompt.prompt })
        .eq('id', translationPrompt.id);

      // Update persona support prompt
      const { error: personaError } = await supabase
        .from('llm_config')
        .update({ prompt: personaSupportPrompt.prompt })
        .eq('id', personaSupportPrompt.id);

      if (translationError || personaError) {
        throw translationError || personaError;
      }

      toast.success('Prompts saved successfully');
    } catch (error) {
      toast.error('Error saving prompts');
      console.error('Error saving data:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const columns = useMemo<MRT_ColumnDef<LlmCall>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 10, // Adjust based on expected ID length
      },
      {
        accessorKey: 'created_at',
        header: 'Created At',
        size: 100, // Adjust based on date format
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
        },
      },
      {
        accessorKey: 'llm_used',
        header: 'LLM Used',
        size: 50, // Adjust based on expected LLM names
      },
      {
        accessorKey: 'system_prompt',
        header: 'Call',
        size: 400, // Larger size for truncated content
        Cell: ({ cell }) => {
          const value = cell.getValue() as string;
          return (
            <span
              className="cursor-pointer text-blue-500 hover:underline"
              onClick={() => setSelectedCall(value)}
            >
              {value && value.length > 100
                ? `${value.slice(0, 100)}...`
                : value}
            </span>
          );
        },
      },
      {
        accessorKey: 'response',
        header: 'Response',
        size: 400, // Larger size for potentially longer content
      },
    ],
    [],
  );

  return (
    <div className="w-full text-white p-4 bg-[#212326]">
      <h2 className="text-2xl font-bold mb-8">Admin</h2>
      {isLoading ? (
        <SkeletonLoader className="w-72" />
      ) : (
        <>
          <section className="">
            <Textarea
              label="Prompt"
              value={translationPrompt.prompt}
              onChange={(value) =>
                setTranslationPrompt({ ...translationPrompt, prompt: value })
              }
              className="mb-5"
              rows={10}
            />

            <Textarea
              label="Persona Support Prompt"
              value={personaSupportPrompt.prompt}
              onChange={(value) =>
                setPersonaSupportPrompt({
                  ...personaSupportPrompt,
                  prompt: value,
                })
              }
              className="mb-5"
              rows={10}
            />

            <Button
              onClick={handleSave}
              disabled={isSaving}
              className="px-4 py-2 mb-8"
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
          </section>
          <section className="">
            <Table data={llmCalls} columns={columns} />
          </section>

          {selectedCall && (
            <Modal
              isOpen={!!selectedCall}
              onClose={() => setSelectedCall(null)}
              title="Full Call Content"
            >
              <pre className="whitespace-pre-wrap break-words">
                {selectedCall}
              </pre>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default Admin;
